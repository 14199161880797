import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  Paper,
  Box,
  IconButton,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {updateInspection, submitInspection, getInspectionsByUser, getInspection} from "../../../actions/awpAction"
import {getLocations, getUsers} from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 400,
      [theme.breakpoints.down("sm")]: {
          minWidth:330
      },
      [theme.breakpoints.down("xs")]: {
          minWidth: "100%",
      }
  },
  formcontrol2: {
    textAlign: "left",
    minWidth: 400,
    [theme.breakpoints.down("sm")]: {
       minWidth:330
    },
    [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
    }

  },

  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },

}));

const INIT_STATE = {
    unitNumber: "",
    inspector: "",
    supervisor: undefined,
    date: "",
    location: undefined,
    visualDamage:"1",
    reason:"",
    fireExtinguisher: "1",
    deadmanSwitch: "1",
    groundControl: "1",
    limitSwitches: "1",
    tiltAlarm: "1",
    tires: "1",
    greasedRotator: "1",
    leaks: "1",
    doors:"1",
    engineOil:"1",
    coolant:"1",
    exhaust:"1",
    beltCondition:"1",
    hydraulicOil:"1",
    hoses:"1",
    auxilliaryMotor:"1",
    guages:"1",
    battery:"1",
    glowPlug: "1",
    keyPlatform: "1",
    joysticks: "1",
    toggleSwitches: "1",
    functions: "1",
    platformDoors: "1",
    footPadal: "1",
    safetyDecals: "1",
    platformToe: "1",
    handrails: "1",
    horn: "1",
    comment:"",
    fuel:"",
    nextService:""
};

const EditInspectionModal = ({ openEditInspection, handleCloseEditInspection, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { awp, error, loading } = useSelector((state) => state.awp);
  const { profile, locations, users } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getInspectionsByUser());
    dispatch(getLocations());
    dispatch(getUsers());
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  useEffect(() => {
    if (id){
      dispatch(getInspection(id));
    }
    
  }, [dispatch, id]);
 
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      unitNumber: awp && awp.unitNumber,
      inspector: awp && awp.inspector,
      supervisor: awp && awp.supervisor,
      date: awp && awp.date,
      location: awp && awp.location._id,
      visualDamage:awp && awp.visualDamage,
      reason:awp && awp.reason,
      fireExtinguisher: awp && awp.fireExtinguisher,
      deadmanSwitch: awp && awp.deadmanSwitch,
      groundControl: awp && awp.groundControl,
      limitSwitches: awp && awp.limitSwitches,
      tiltAlarm: awp && awp.tiltAlarm,
      tires: awp && awp.tires,
      greasedRotator: awp && awp.greasedRotator,
      leaks: awp && awp.leaks,
      doors:awp && awp.doors,
      engineOil:awp && awp.engineOil,
      coolant:awp && awp.coolant,
      exhaust:awp && awp.exhaust,
      beltCondition:awp && awp.beltCondition,
      hydraulicOil:awp && awp.hydraulicOil,
      hoses:awp && awp.hoses,
      auxilliaryMotor:awp && awp.auxilliaryMotor,
      guages:awp && awp.guages,
      battery:awp && awp.battery,
      glowPlug: awp && awp.glowPlug,
      keyPlatform: awp && awp.keyPlatform,
      joysticks: awp && awp.joysticks,
      toggleSwitches: awp && awp.toggleSwitches,
      functions: awp && awp.functions,
      platformDoors: awp && awp.platformDoors,
      footPadal: awp && awp.footPadal,
      safetyDecals: awp && awp.safetyDecals,
      platformToe: awp && awp.platformToe,
      handrails: awp && awp.handrails,
      horn: awp && awp.horn,
      comment:awp && awp.comment,
      fuel:awp && awp.fuel,
      nextService:awp && awp.nextService

    }));
  }, [awp]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    
  };

  const handleSave = (e) => {
    e.preventDefault();
    let data = {
      unitNumber: state.unitNumber,
      inspector: state.inspector,
      supervisor: state.supervisor,
      date: state.date,
      location: state.location,
      visualDamage:state.visualDamage,
      reason:state.reason,
      fireExtinguisher: state.fireExtinguisher,
      deadmanSwitch: state.deadmanSwitch,
      groundControl: state.groundControl,
      limitSwitches: state.limitSwitches,
      tiltAlarm: state.tiltAlarm,
      tires: state.tires,
      greasedRotator: state.greasedRotator,
      leaks: state.leaks,
      doors:state.doors,
      engineOil:state.engineOil,
      coolant:state.coolant,
      exhaust:state.exhaust,
      beltCondition:state.beltCondition,
      hydraulicOil:state.hydraulicOil,
      hoses:state.hoses,
      auxilliaryMotor:state.auxilliaryMotor,
      guages:state.guages,
      battery:state.battery,
      glowPlug: state.glowPlug,
      keyPlatform: state.keyPlatform,
      joysticks: state.joysticks,
      toggleSwitches: state.toggleSwitches,
      functions: state.functions,
      platformDoors: state.platformDoors,
      footPadal: state.footPadal,
      safetyDecals: state.safetyDecals,
      platformToe: state.platformToe,
      handrails: state.handrails,
      horn: state.horn,
      comment:state.comment,
      fuel:state.fuel,
      nextService:state.nextService,
      id
    };
    dispatch(updateInspection(data));

    setTimeout(() => {
      handleCloseEditInspection();
      dispatch(getInspectionsByUser());
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Inspection Saved Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      unitNumber: state.unitNumber,
      inspector: state.inspector,
      supervisor: state.supervisor,
      date: state.date,
      location: state.location,
      visualDamage:state.visualDamage,
      reason:state.reason,
      fireExtinguisher: state.fireExtinguisher,
      deadmanSwitch: state.deadmanSwitch,
      groundControl: state.groundControl,
      limitSwitches: state.limitSwitches,
      tiltAlarm: state.tiltAlarm,
      tires: state.tires,
      greasedRotator: state.greasedRotator,
      leaks: state.leaks,
      doors:state.doors,
      engineOil:state.engineOil,
      coolant:state.coolant,
      exhaust:state.exhaust,
      beltCondition:state.beltCondition,
      hydraulicOil:state.hydraulicOil,
      hoses:state.hoses,
      auxilliaryMotor:state.auxilliaryMotor,
      guages:state.guages,
      battery:state.battery,
      glowPlug: state.glowPlug,
      keyPlatform: state.keyPlatform,
      joysticks: state.joysticks,
      toggleSwitches: state.toggleSwitches,
      functions: state.functions,
      platformDoors: state.platformDoors,
      footPadal: state.footPadal,
      safetyDecals: state.safetyDecals,
      platformToe: state.platformToe,
      handrails: state.handrails,
      horn: state.horn,
      comment:state.comment,
      fuel:state.fuel,
      nextService:state.nextService,
      path: "awp/inspection/view",
      id
    };
    dispatch(submitInspection(data));
    setTimeout(() => {
      handleCloseEditInspection()
        dispatch(getInspectionsByUser());
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Inspection Submitted Successfully.",
          success: true,
        }));
    }, 1500);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.unitNumber || !state.location

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openEditInspection}
        onClose={handleCloseEditInspection}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit AWP Inspection
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseEditInspection}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
          <form onSubmit={handleSubmit}>
            
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="unitNumber"
                    fullWidth
                    variant="outlined"
                    label="Unit Number"
                    onChange={handleChange}
                    value={state.unitNumber || ""}
                />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                      name="inspector"
                      fullWidth
                      variant="outlined"
                      label="Operator"
                      onChange={handleChange}
                      value={state.inspector || ""}
                      required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <Autocomplete 
                            id="supervisor"
                            options={users}
                            getOptionSelected={(option, value) => option.displayname === state.supervisor?.displayname}
                            getOptionLabel={(option) => option.displayname || ''} 
                            onChange={(e, value) => setState((prevState) => ({ ...prevState, supervisor: value }))}
                            value={state.supervisor || ''}
                            renderInput={(params) => 
                            <TextField {...params} variant="outlined" label="Supervisor" />} 
                        />
                    </FormControl>
                    
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="date"
                      fullWidth
                      variant="outlined"
                      label="Date"
                      onChange={handleChange}
                      value={state.date?.split("T")[0] || ''}
                      required
                      type="date"   
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                    Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={state.location || ""}
                      name="location"
                      onChange={handleChange}
                      label="Location"
                      required
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {locations &&
                        locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            {location.displayname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" className={classes.formcontrol2}>
                    <InputLabel id="demo-simple-select-outlined-label">
                        Fuel Level
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        fullWidth
                        value={state.fuel || ""}
                        name="fuel"
                        onChange={handleChange}
                        label="Fuel Level"
                        variant="outlined"
                    >
                        <MenuItem value="Empty">Empty</MenuItem>
                        <MenuItem value="1/4">1/4</MenuItem>
                        <MenuItem value="1/2">1/2</MenuItem>
                        <MenuItem value="3/4">3/4</MenuItem>
                        <MenuItem value="Full">Full</MenuItem>
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
              <Typography variant="body2" gutterBottom color="primary">
                  1 - Ok;
                  2 - Requires Scheduled Maintenance;
                  3 - Requires Immediate Fix
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Walk Around Visual Damage Inspection</FormLabel>
                  <RadioGroup row aria-label="visualDamage" name="visualDamage" value={state.visualDamage || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1" />
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2" />
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {(state.visualDamage == "2" || state.visualDamage == "3") && (
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="reason"
                  fullWidth
                  variant="outlined"
                  label="Briefly describe the damage"
                  onChange={handleChange}
                  multiline
                  value={state.reason || ""}
                  />
              </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Fire Extinguisher</FormLabel>
                  <RadioGroup row name="fireExtinguisher" value={state.fireExtinguisher || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Deadman Switch</FormLabel>
                  <RadioGroup row name="deadmanSwitch" value={state.deadmanSwitch || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Ground Control Box</FormLabel>
                  <RadioGroup row name="groundControl" value={state.groundControl || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Limit Switches</FormLabel>
                  <RadioGroup row name="limitSwitches" value={state.limitSwitches || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Tilt Alarm</FormLabel>
                  <RadioGroup row name="tiltAlarm" value={state.tiltAlarm || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Tires and Lug Nuts</FormLabel>
                  <RadioGroup row name="tires" value={state.tires || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Greased Rotator Assembly</FormLabel>
                  <RadioGroup row name="greasedRotator" value={state.greasedRotator || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Leaks</FormLabel>
                  <RadioGroup row name="leaks" value={state.leaks || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Doors and Latches</FormLabel>
                  <RadioGroup row name="doors" value={state.doors || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Engine Oil Level</FormLabel>
                  <RadioGroup row name="engineOil" value={state.engineOil || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Coolant Level</FormLabel>
                  <RadioGroup row name="coolant" value={state.coolant || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Exhaust, Air Induction</FormLabel>
                  <RadioGroup row name="exhaust" value={state.exhaust || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Belt Condition and Tension</FormLabel>
                  <RadioGroup row name="beltCondition" value={state.beltCondition || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Hydraulic Oil Level</FormLabel>
                  <RadioGroup row name="hydraulicOil" value={state.hydraulicOil || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Hoses and Leaks</FormLabel>
                  <RadioGroup row name="hoses" value={state.hoses || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Auxilliary Motor</FormLabel>
                  <RadioGroup row name="auxilliaryMotor" value={state.auxilliaryMotor || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Guages</FormLabel>
                  <RadioGroup row name="guages" value={state.guages || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Battery Connections - Clean and Dry</FormLabel>
                  <RadioGroup row name="battery" value={state.battery || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Glow Plug - if equipped</FormLabel>
                  <RadioGroup row name="glowPlug" value={state.glowPlug || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Key Platform</FormLabel>
                  <RadioGroup row name="keyPlatform" value={state.keyPlatform || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Joysticks and Levers</FormLabel>
                  <RadioGroup row name="joysticks" value={state.joysticks || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >All Toggle Switches Functional</FormLabel>
                  <RadioGroup row name="toggleSwitches" value={state.toggleSwitches || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >All Functions Operational</FormLabel>
                  <RadioGroup row name="functions" value={state.functions || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Platform Doors or Safety Chain</FormLabel>
                  <RadioGroup row name="platformDoors" value={state.platformDoors || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Foot Pedal Operational</FormLabel>
                  <RadioGroup row name="footPedal" value={state.footPadal || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Safety Decals Good - Platform & Ground</FormLabel>
                  <RadioGroup row name="safetyDecals" value={state.safetyDecals || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Platform Toe Guards</FormLabel>
                  <RadioGroup row name="platformToe" value={state.platformToe || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Handrails</FormLabel>
                  <RadioGroup row name="handrails" value={state.handrails || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl  variant="outlined" className={classes.formcontrol2}>
                  <FormLabel >Horn</FormLabel>
                  <RadioGroup row name="horn" value={state.horn || ""} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="1"/>
                    <FormControlLabel value="2" control={<Radio color="secondary"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio color="secondary"/>} label="3"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
                
              <Grid item xs={12} sm={6}>
              <TextField
                    name="nextService"
                    fullWidth
                    variant="outlined"
                    label=" Next Service Due Date"
                    onChange={handleChange}
                    value={state.nextService?.split("T")[0] || ''}
                    type="date"     
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                  <TextField
                  name="comment"
                  fullWidth
                  variant="outlined"
                  label="Comments/Rapairs Required"
                  onChange={handleChange}
                  multiline
                  value={state.comment || ""}
                  />
              </Grid>
   
            </Grid>

            <br />
          
            <Typography align="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSave}
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save"}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  className={classes.btn2}
                  disabled={isValid() || loading}
                >
                  {loading ? "Loading..." : "Save & Submit"}
                </Button>
              </Typography>
            </form>
          </Box>
          </Paper>


        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditInspectionModal;
